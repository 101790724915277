import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import fb from '../../assets/fbimg.png';
import twitter from '../../assets/twitterimg.png';
import linkedin from '../../assets/linkedinimg.png';
import insta from '../../assets/instaimg.png';

const Footer = () => (
  <div className="sb__footer section__padding">
    <div className="sb__footer-links">
      <div className="sb__footer-links_div">
        <h4>For Business</h4>
        <Link to="/employer">
          <p>Organization</p>
        </Link>
        {/* <Link to="/healthplan">
          <p>Health Plan</p>
        </Link> */}
        <Link to="/individual">
          <p>Individual</p>
        </Link>
      </div>
      {/* <div className="sb__footer-links_div">
        <h4>Resources</h4>
        <Link to="/resource"><p>Resource center</p></Link>
        <Link to="/resource"><p>Testimonials</p></Link>
        <p>SBTV</p>
      </div> */}
      {/* <div className="sb__footer-links_div">
        <h4>Partners</h4>
        <Link to="/embr"><p>EMBR Labs</p></Link>
      </div> */}
      <div className="sb__footer-links_div">
        <h4>Company</h4>
        <Link to="/whatwedo"><p>About</p></Link>
        <p>Press</p>
        <Link to="/about"><p>Career</p></Link>
        <p>Contact</p>
      </div>
      <div className="sb__footer-links_div">
        <h4>Coming soon on</h4>
        <div className="socialmedia">
        <p><img src={fb} alt=''/></p>
        <p><img src={twitter} alt=''/></p>
        <p><img src={linkedin} alt=''/></p>
        <p><img src={insta} alt=''/></p>
        </div>
      </div>
    </div>
  
  <hr color="#6364D5"></hr>
     
     <div className="sb__footer-below">
    <div className="sb__footer-copyright">
      <p>
        @{new Date().getFullYear()} Second Bloom HQ, Inc. All rights reserved.
      </p>
    </div>
    <div className="sb__footer-below-links">
      <Link to='/terms'><div><p>Terms & conditions</p></div></Link>
      <Link to='/privacy'><div><p>Privacy</p></div></Link>
      {/* <Link><div><p>Security</p></div></Link>
      <Link><div><p>Cookie declaration</p></div></Link> */}
    </div>
    </div>
  </div>
);

export default Footer;
