import React from "react";
import "./connect.css";
import organizationimg from "../../assets/newimages/organizationimg.png";
import individualimg from "../../assets/newimages/individualimg.jpg";
import { Link } from "react-router-dom";

const Connect = () => {
  return (
    <div className="sb__connect-main section__padding">
      <div className="sb__connect-title">
        <h1>Connect with Us</h1>
      </div>
      {/* <div className="sb__connect-subtitle">
        <p>To checkout what Second Bloom Health can offer your employees, members, or you as an individual</p>
      </div> */}
      <div className="sb__connect-boxes-div">
        <Link to="/employer">
          <div className="sb__employer-box">
            <div className="sb__employer-box-img">
              <img src={organizationimg} alt="" />
            </div>
            <div className="sb__employer-box-text">
              <h3>Organizations</h3>
              <p>
                Discover how Second Bloom Health can support your midlife women
                employees or members
              </p>
              <button>Find out more</button>
            </div>
          </div>
        </Link>
        {/* <Link to="/healthplan">
        <div className="sb__healthplan-box">
          <div className="sb__healthplan-box-img">
            <img src={healthplansimg} alt="" />
          </div>
          <div className="sb__employer-box-text">
            <h3>Health Plans</h3>
            <p>Provide a menopause benefit to your most important members</p>
          </div>
        </div>
        </Link> */}
        <Link to="/individual">
          <div className="sb__individual-box">
            <div className="sb__healthplan-box-img">
              <img src={individualimg} alt="" />
            </div>
            <div className="sb__employer-box-text">
              <h3>Individuals</h3>
              <p>
                Discover how Second Bloom Health can help you feel healthier,
                happier and more in control of your journey though perimenopause
                and menopause
              </p>
              <button>Find out more</button>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Connect;
