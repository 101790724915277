import React from "react";
import "./about.css";
import aboutperson from "../../assets/about_person.jpg";
import Footer from "../footer/Footer";

const About = () => {
  return (
    <div>
      <div className="sb__about">
        <div className="sb__about-mainheading section__padding">
        <p>EMPOWERING MIDLIFE WOMEN</p>
          <h1>
            Join our holistic movement to normalize menopause and help women get
            the information and support they need to live healthier, happier and
            more productive lives
          </h1>
        
        </div>
      </div>
      {/* <div className="team__linebreak section__padding">
            <hr></hr>
            </div> */}
      <div className="sb__about-team section__padding">{/* <Team /> */}</div>
      <div className="sb__about-below">
        <div className="sb__about-below-content section__padding">
          <div className="sb__about-img">
            <img src={aboutperson} alt="" />
          </div>
          <div className="sb__about-textbutton">
            <h3>Join our team and contribute towards empowering women</h3>
            <p>Explore careers at Second Bloom Health</p>
            <button>View Careers</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
