import React from "react";
import "./resource.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Testimonial from '../testimonialpage/testimonial';
import Footer from '../footer/Footer';

import book1 from "../../assets/books/book1.png";
import book2 from "../../assets/books/book2.png";
import book3 from "../../assets/books/book3.png";
import book4 from "../../assets/books/book4.png";
import book5 from "../../assets/books/book5.png";
import book6 from "../../assets/books/book6.png";
import book7 from "../../assets/books/book7.png";
import maga1 from "../../assets/books/magazine1.png";

const Resource = () => {
  return (
    <div>
      <div className="sb__resource">
        <div className="section__padding">
          <div className="sb__resource-title">
            <h1>Menopause Health and Wellness Resource Center</h1>
          </div>
          <div className="sb__resource-books-section">
            <div className="sb__resource-books-section-title">
              <p>BOOKS</p>
            </div>
            <div className="sb__resource-books-group">
              <BookCard
                bookimg={book1}
                booktitle="Get back in balance"
                bookdesc="Learn more about getting balance in your health"
                alink="https://drmache.com/balance-book/"
              />

              <BookCard
                bookimg={book2}
                booktitle="Woman's book of yoga"
                bookdesc="Learn more about woman’s yoga"
                alink="https://www.amazon.com/Womans-Book-Yoga-Embracing-Natural/dp/1583331379/ref=sr_1_4?crid=31J07U22NFT91&keywords=a+woman%27s+book+of+yoga&qid=1668794318&s=books&sprefix=a+womans+book+of+yoga+%2Cstripbooks%2C69&sr=1-4"
              />

              <BookCard
                bookimg={book3}
                booktitle="Eat to defeat menopause"
                bookdesc="Learn more about
                diet for menopausal health"
                alink="https://www.amazon.com/Eat-Defeat-Menopause-Essential-Midlife/dp/0738215090/ref=sr_1_2?qid=1668794110&refinements=p_27%3AMache+Seibel+MD&s=books&sr=1-2&text=Mache+Seibel+MD"
              />

              <BookCard
                bookimg={book4}
                booktitle="The Estrogen fix"
                bookdesc="Breakthrough guide to
                being healthy, energized"
                alink="https://www.amazon.com/Estrogen-Fix-Breakthrough-Energized-Hormonally/dp/1635650127/ref=sr_1_2?crid=94W2166VU7C3&keywords=menopause+breakthrough+system&qid=1668794661&s=books&sprefix=menopause+breakthrough+system%2Cstripbooks%2C63&sr=1-2"
              />

              <BookCard
                bookimg={book5}
                booktitle="Soy for menopause"
                bookdesc="Learn how soy helps
                in menopause health"
                alink="https://www.amazon.com/Soy-Solution-Menopause-Estrogen-Alternative/dp/0743421523/ref=sr_1_1?crid=39PU99KYDXZ6K&keywords=the+soy+solution&qid=1668794247&s=books&sprefix=the+soy+solution%2Cstripbooks%2C68&sr=1-1"
              />

              <BookCard
                bookimg={book6}
                booktitle="Impact of menopause"
                bookdesc="Learn how menopause
                impacts on woman’s
                business, health"
                alink="https://www.amazon.com/Working-Through-Menopause-Impact-Businesses/dp/1667807641/ref=sr_1_1?qid=1668794110&refinements=p_27%3AMache+Seibel+MD&s=books&sr=1-1&text=Mache+Seibel+MD"
              />

              <BookCard
                bookimg={book7}
                booktitle="The Estrogen Window"
                bookdesc="Guide to Perimenopause, Menopause, and Beyond"
                alink="https://www.amazon.com/Estrogen-Window-Breakthrough-Balanced-Through-Perimenopause/dp/1623366747"
              />
            </div>
          </div>
          <div className="sb__resource-books-section-title">
            <p>MAGAZINES</p>
          </div>

          <div className="sb__resource-books-group">
            <BookCard
              bookimg={maga1}
              booktitle="The Hot Years"
              bookdesc="Get the #1 menopause magazine"
              alink="https://www.mymenopausemag.com/"
            />
          </div>

          <div className="sb__resource-books-section-title blogs">
            <p>BLOGS</p>
          </div>
          
          <a href="https://drmache.com/dr-mache-blog/" target='blank'>
            <div className="embr_wave-button">
              <button>Dr. Mache's Blog</button>
            </div>
            </a>

          <div className="sb__resource-magazine-section-title">
            <p>ARTICLES</p>
          </div>

          <div className="sb__resource-article-group">
            <Article arlink="https://hbr.org/2020/02/its-time-to-start-talking-about-menopause-at-work" magatitle="It’s Time to Start Talking About Menopause at Work"/>
            <Article arlink="https://www.healthywomen.org/your-health/talk-to-your-healthcare-provider-about-menopause" magatitle="How to talk to your healthcare provider about menopause"/>
            <Article arlink="https://www.menopause.org/for-women" magatitle="Menopause and me"/>
          </div>
        </div>
      </div>
      <Testimonial/>
      <Footer/>
    </div>
  );
};

const BookCard = (props) => {
  return (
   <a href={props.alink} target='blank'> <div className="bookcard">
      <div className="bookcard-img">
        <img src={props.bookimg} alt="" />
      </div>
      <div className="bookcard-text">
        <div>
          <h5>{props.booktitle}</h5>
        </div>
        <div>
          <p>{props.bookdesc}</p>
        </div>
        <div>
          <BsFillArrowRightCircleFill />
        </div>
      </div>
    </div></a>
  );
};

const Article = (props) => {
  return (
    <a href={props.arlink} target='blank'><div className="article">
      <div>
        <p>{props.magatitle}</p>
      </div>
      <div>
        <BsFillArrowRightCircleFill />
      </div>
    </div></a>
  );
};

export default Resource;
