import React, { useState } from "react";
import "./accordian.css";
import { MdAdd } from "react-icons/md";
import { BiMinusCircle } from "react-icons/bi";

const Accordian = (props) => {
  const [show, setShow] = useState(false);
  
  return (
    <div className="sb__accordian-div">
      <div className="sb__accordian-ques-icon">
        <div className="accordian-add">
          <p onClick={() => setShow(!show)}>
            {show ? (
              <BiMinusCircle style={{ marginRight: "1rem" }} />
            ) : (
              <MdAdd style={{ marginRight: "1rem" }} />
            )}
          </p>
        </div>
        <div className="ques-answer">
          <h3>{props.question}</h3>
          {show && <p>{props.answer}<br></br>
          
          {
          props.points.map((element, index)=>{
            return(
             <li>{index+1}: {element}</li>)
          })
        }
          
          </p>}
        </div>
      </div>
    </div>
  );
};

export default Accordian;
