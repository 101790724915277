import React from "react";
import Landingpage from "./landingpage/landingpage";
import Coach from "./guidecoachpage/coach";
import Fitness from "./guidedfitnesspage/fitness";
import Connect from "./connectpage/connect";
import Subscribe from "./subscribepage/subscribe";
import Footer from "./footer/Footer";

import Introducing from "./introducing/introducing";
import Trust from "./trust/trust";


const Home=()=>{
    return(
      <>
      <Landingpage/>
      <Coach/>
      <Introducing/>
      <Fitness/>
      <Trust/>
      {/* <Team/> */}
      <Connect/>
      <Subscribe/>
      <Footer/>
      </>
    );
};

export default Home;