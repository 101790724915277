import React from "react";
import Footer from "../footer/Footer";
import "./privacy.css";

const Privacy = () => {
  return (
    <div>
      <div className="sb_terms">
        <div className="section__padding">
          <div className="sb_terms-title privacy-title">
            <h2>SECOND BLOOM PRIVACY POLICY</h2>
          </div>
          <div className="sb_terms-des">
            <p>
              This Privacy Policy describes how Second Bloom HQ Inc. (
              <span className="terms_bold">“Second Bloom”</span>) handles
              personal information that we collect through our website,
              application, and any other sites or services that link to this
              Privacy Policy (collectively, the “Services”).<br></br>
              <br></br>
              <span className="terms_bold">
                Personal information we collect
              </span>
              <br></br>
              <br></br>
              Information users submit to us:<br></br>
              <br></br>
              <ul>
                <li>
                  <span className="terms_bold">
                    Contact and account information,
                  </span> such as your first and last name, email address, password,
                  information about your health, your wellness goals, and other
                  similar information.
                </li>

                <li>
                  <span className="terms_bold">
                    Wearable Device Information,
                  </span> which includes information provided from a smart watch,
                  wearable device, or other remote monitoring or lott device that
                  you connect to our Service. Such information may include
                  information related to your heart rate, sleep, and physical
                  activity.
                </li>

                <li>
                  <span className="terms_bold">
                    Feedback or correspondence,
                  </span> such as information you provide when you respond to surveys or
                  contact us with questions, feedback, or otherwise correspond
                  with us online.
                </li>

                <li>
                  <span className="terms_bold">Usage information, </span>
                  such as information about how you use the Services and
                  interact with us, including information associated with any
                  content you upload to the Services or otherwise submit, and
                  information you provide when you use any interactive features
                  of the Services.
                </li>

                <li>
                  <span className="terms_bold">Marketing information, </span>
                  such as your preferences for receiving communications about
                  our Services, and details about how you engage with our
                  communications.
                </li>

                <li>
                  <span className="terms_bold">Other information, </span>
                  that we may collect which is not specifically listed here, but
                  which we will use in accordance with this Privacy Policy or as
                  otherwise disclosed at the time of collection.
                </li>
              </ul>
              <br></br>
              <br></br>
              <span className="terms_bold">
                Information we obtain from third parties:
              </span>
              <br></br>
              <br></br>
              <ul>
                <li>
                  <span className="terms_bold">Social media information.</span>
                  We may maintain pages on social media platforms, such as
                  Facebook, TikTok, Instagram, LinkedIn, Twitter, and other
                  third-party platforms. When you visit or interact with our
                  pages on those platforms, the platform provider’s privacy
                  policy will apply to your interactions and their collection,
                  use and processing of your personal information. You or the
                  platforms may provide us with information such as your
                  biographical, educational, and professional experience, and we
                  will treat such information in accordance with this Privacy
                  Policy.
                </li>
                <li>
                  <span className="terms_bold">Other sources.</span> We may
                  obtain your personal information from other third parties,
                  such as marketing partners, publicly-available sources and
                  data providers.
                </li>
              </ul>
              <br></br>
              <br></br>
              <span className="terms_bold">Automatic data collection.</span> We
              and our service providers may automatically log information about
              you, your computer or mobile device, and your interactions over
              time with our Services, our communications and other online
              services, such as:<br></br>
              <br></br>
              <ul>
                <li>
                  <span className="terms_bold">Device data,</span> such as your
                  computer’s or mobile device’s operating system type and
                  version, manufacturer and model, browser type, screen
                  resolution, RAM and disk size, CPU usage, device type (e.g.,
                  phone, tablet), IP address, unique identifiers, language
                  settings, mobile device carrier, radio/network information
                  (e.g., WiFi, LTE, 4G), and general location information such
                  as city, state or geographic area.
                </li>

                <li>
                  <span className="terms_bold">Online activity data,</span> such
                  as pages or screens you viewed, how long you spent on a page
                  or screen, browsing history, navigation paths between pages or
                  screens, information about your activity on a page or screen,
                  access times, duration of access, and whether you have opened
                  our marketing emails or clicked links within them.
                </li>
              </ul>
              <br></br>
              <br></br>
              <span className="terms_bold">
                We use the following tools for automatic data collection:
              </span>
              <br></br>
              <br></br>
              <ul>
                <li>
                  <span className="terms_bold">Cookies,</span> which are text
                  files that websites store on a visitor’s device to uniquely
                  identify the visitor’s browser or to store information or
                  settings in the browser for the purpose of helping you
                  navigate between pages efficiently, remembering your
                  preferences, enabling functionality, and helping us understand
                  user activity and patterns.
                </li>

                <li>
                  <span className="terms_bold">
                    Local storage technologies,
                  </span>{" "}
                  like HTML5, that provide cookie-equivalent functionality but
                  can store larger amounts of data, including on your device
                  outside of your browser in connection with specific
                  applications.
                </li>

                <li>
                  <span className="terms_bold">Web beacons,</span> also known as
                  pixel tags or clear GIFs, which are used to demonstrate that a
                  webpage or email was accessed or opened, or that certain
                  content was viewed or clicked.
                </li>
              </ul>
              <br></br>
              <br></br>
              <span className="terms_bold">
                How we use your personal information
              </span>
              <br></br>
              <br></br>
              <span className="terms_bold">To operate our Services:</span>
              <br></br>
              •Provide, operate, maintain, secure and improve our Services.
              <br></br>
              •Provide our users with customer service.<br></br>
              •Communicate with you about our Services, including by sending you
              announcements, updates, security alerts, and support and
              administrative messages.<br></br>
              •Understand your needs and interests, and personalize your
              experience with our Services and our communications.<br></br>
              •Respond to your requests, questions and feedback.<br></br>
              <br></br>
              <span className="terms_bold">
                For research and development.
              </span>{" "}
              We may use your personal information for research and development
              purposes, including to analyze and improve our Services and our
              business. As part of these activities, we may create aggregated,
              de-identified, or other anonymous data from personal information
              we collect. We make personal information into anonymous data by
              removing information that makes the data personally identifiable
              to you. We may use this anonymous data and share it with third
              parties for our lawful business purposes, including to analyze and
              improve our Services and promote our business.<br></br>
              <br></br>
              <span className="terms_bold">Direct marketing.</span> We may from
              time-to-time send you direct marketing communications as permitted
              by law, including, but not limited to, notifying you of special
              promotions, offers and events via email and in-app notifications.
              You may opt out of our marketing communications as described in
              the “Opt out of marketing communications” section below.<br></br>
              <br></br>
              <span className="terms_bold">Compliance and protection.</span> We
              may use personal information to:<br></br>
              <br></br>
              •Comply with applicable laws, lawful requests, and legal process,
              such as to respond to subpoenas or requests from government
              authorities.<br></br>
              •Protect our, your or others’ rights, privacy, safety or property
              (including by making and defending legal claims).<br></br>
              •Audit our internal processes for compliance with legal and
              contractual requirements and internal policies.<br></br>
              •Enforce the terms and conditions that govern our Services.
              <br></br>
              •Prevent, identify, investigate and deter fraudulent, harmful,
              unauthorized, unethical or illegal activity, including cyberattacks
              and identity theft.<br></br>
              <br></br>
              <span className="terms_bold">
                How we share your personal information
              </span>
              <br></br>
              <br></br>
              <span className="terms_bold">Service providers.</span> We may
              share your personal information with third party companies and
              individuals that provide services on our behalf or help us operate
              our Services (such as lawyers, bankers, auditors, insurers,
              customer support, hosting, analytics, email delivery, marketing,
              and database management).<br></br>
              <br></br>
              <span className="terms_bold">Authorities and others.</span> We may
              disclose your personal information to law enforcement, government
              authorities, and private parties, as we believe in good faith to
              be necessary or appropriate for the compliance and protection
              purposes described above.<br></br>
              <br></br>
              <span className="terms_bold">Business transfers.</span> We may
              sell, transfer or otherwise share some or all of our business or
              assets, including your personal information, in connection with a
              business transaction (or potential business transaction) such as a
              corporate divestiture, merger, consolidation, acquisition,
              reorganization or sale of assets, or in the event of bankruptcy or
              dissolution. In such a case, we will make reasonable efforts to
              require the recipient to honor this Privacy Policy.<br></br>
              <br></br>
              <span className="terms_bold">Your choices</span>
              <br></br>
              <br></br>
              <span className="terms_bold">
                Access or update your information.
              </span>{" "}
              Users may themselves update their personal information in their
              accounts by logging in and editing their information.<br></br>
              <span className="terms_bold">
                Opt out of marketing communications.
              </span>{" "}
              You may opt out of marketing-related emails by following the
              opt-out or unsubscribe instructions at the bottom of the email. You
              may continue to receive service-related and other non-marketing
              emails.<br></br>
              <span className="terms_bold">Online tracking opt-out.</span> There
              are a number of ways to limit online tracking, which we have
              summarized below: <br></br>
              <br></br>
              <span className="terms_bold">
                •Blocking cookies in your browser.
              </span>{" "}
              Most browsers let you remove or reject cookies. To do this, follow
              the instructions in your browser settings. Many browsers accept
              cookies by default until you change your settings. For more
              information about cookies, including how to see what cookies have
              been set on your device and how to manage and delete them, visit
              www.allaboutcookies.org.<br></br>
              <span className="terms_bold">
                •Using privacy plug-ins or browsers.
              </span>{" "}
              You can block our Services from setting cookies by using a browser
              with privacy features, like Brave, or installing browser plugins
              like Privacy Badger, DuckDuckGo, Ghostery or uBlock Origin, and
              configuring them to block third party cookies/trackers.<br></br>
              <span className="terms_bold">•Google analytics.</span> We use
              Google Analytics to collect and summarize information about how
              individuals engage with the Services. For more information on
              Google Analytics, click here. For more information about Google’s
              privacy practices, click here. You can opt out of Google Analytics
              by downloading and installing the browser plug-in available at:
              https://tools.google.com/dlpage/ gaoptout. <br></br>
              <br></br>
              Note that because these opt-out mechanisms are specific to the
              device or browser on which they are exercised, you will need to
              opt out on every browser and device that you use.<br></br>
              <br></br>
              <span className="terms_bold">Do Not Track.</span> Some Internet
              browsers may be configured to send “Do Not Track” signals to the
              online services that you visit. We currently do not respond to “Do
              Not Track” or similar signals. To find out more about “Do Not
              Track,” please visit http://www.allaboutdnt.com.<br></br>
              <br></br>
              <span className="terms_bold">
                Other sites, mobile applications and services
              </span>
              <br></br>
              <br></br>
              Our Services may contain links to other websites, mobile
              applications, and other online services operated by third parties.
              These links are not an endorsement of, or representation that we
              are affiliated with, any third party. In addition, our content may
              be included on web pages or in mobile applications or online
              services that are not associated with us. We do not control third
              party websites, mobile applications or online services, and we are
              not responsible for their actions. Other websites and services
              follow different rules regarding the collection, use and sharing
              of your personal information. We encourage you to read the privacy
              policies of the other websites and mobile applications and online
              services you use.<br></br>
              <br></br><br></br>
              <span className="terms_bold">Security</span>
              <br></br>
              We employ a number of technical, organizational, and physical
              safeguards designed to protect the personal information we
              collect. However, no security measures are failsafe and we cannot
              guarantee the security of your personal information. <br></br><br></br>
              <span className="terms_bold">Children</span>
              <br></br>
              Our Services are not intended for use by individuals under 18
              years of age. If we learn that we have collected personal
              information through the Services from a child under 18 without the
              consent of the child’s parent or guardian as required by law, we
              will delete it.<br></br><br></br>
              <span className="terms_bold">Changes to this Privacy Policy</span>
              <br></br>
              We reserve the right to modify this Privacy Policy at any time. If
              we make material changes to this Privacy Policy, we will notify
              you by updating the date of this Privacy Policy and posting it on
              the website.<br></br><br></br>
              <span className="terms_bold">How to contact us</span>
              <br></br>
              Please direct any questions or comments about this Privacy Policy
              or our privacy practices to
              customer.support@secondbloomhealth.com.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
