import React from "react";
import "./healthplan.css";
import healthplan_humanimg from "../../assets/healthplan_humanimg.png";
import healthplan_cartoonimg from "../../assets/healthplan_cartoonimg.png";
import { IoIosCall } from "react-icons/io";
import Footer from "../footer/Footer";

const Healthplan = () => {
  return (
    <div>
      <div className="sb__employer section__padding">
        <div className="top-part">
          <div className="top-part-text">
            <h1>
              <span className="healthplans">Health Plans</span><br></br>Find out how
              your women clients can benefit from Second Bloom Health
            </h1>
            <p>
              Perimenopause and menopause are often overlooked which can affect
              physical and mental wellbeing.  Give your members the ability to
              address this critical transition in a woman's life to improve
              health care, boost productivity and improve retention.
            </p>
            <h4>Connect with us</h4>
          </div>
          <div className="top-part-img">
            <img src={healthplan_humanimg} alt="" />
          </div>
        </div>

        <div className="sb__employer-main">
          <div className="sb__employer-formbox">
            <div className="employer-formbox-text-img">
              <h3>Get in touch with us</h3>
              <p>
                Learn what<br></br>Second Bloom HQ<br></br>can bring to your members.
              </p>
              <img src={healthplan_cartoonimg} alt="" />
            </div>
            <div className="employer-formbox-textfield">
              <form>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <input type="text" name="firstname" required />
                    <label>First Name:</label>
                  </div>
                  <div class="employer-form-item">
                    <input type="text" name="lastname" required />
                    <label>Last Name:</label>
                  </div>
                </div>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <input type="text" name="jobtitle" required />
                    <label>Job Title:</label>
                  </div>
                  <div class="employer-form-item">
                    <input
                      type="tel"
                      name="phone"
                      minLength="10"
                      maxLength="10"
                      required
                    />
                    <label>Phone Number:</label>
                  </div>
                </div>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <input type="email" name="email" required />
                    <label>Work Email:</label>
                  </div>
                  <div class="employer-form-item">
                    <input type="text" name="companyname" required />
                    <label>Company Name:</label>
                  </div>
                </div>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <select id="companysize" name="companysize" required>
                    <option value="volvo">company size</option>
                      <option value="volvo">1-10 employees</option>
                      <option value="saab">11-50 employees</option>
                      <option value="fiat">51-200 employees</option>
                      <option value="audi">201-500 employees</option>
                      <option value="fiat">501-1000 employees</option>
                      <option value="audi">1000+ employees</option>
                    </select>
                    <label>Company Size:</label>
                  </div>
                  <div class="employer-form-item">
                    <select id="industry" name="industry" required>
                      <option value="volvo">Industry</option>
                      <option value="saab">Accounting, Administration & Office support</option>
                      <option value="fiat">Advertising</option>
                      <option value="audi">Arts & Media</option>
                      
                      <option value="audi">Banking & Financial Services</option>
                      <option value="audi">Call Center & Customer Service</option>
                      <option value="audi">Community Services & Development</option>
                      <option value="audi">Construction</option>
                      <option value="audi">Consulting & Strategy</option>
                      <option value="audi">Design & Architecture</option>
                      <option value="audi">Education & Training</option>
                      <option value="audi">Engineering</option>
                      <option value="audi">Framing & Animals & Conservation</option>
                      <option value="audi">Government & Defence</option>
                      <option value="audi">Healthcare & Medical</option>
                      <option value="audi">Hospitality & Tourism</option>
                      <option value="audi">Human Resources & Recruitment</option>
                      <option value="audi">Information & Communication Technology</option>
                      <option value="audi">Insurance</option>
                      <option value="audi">Legal</option>
                      <option value="audi">Manufacturing & Transport & Logistics</option>
                      <option value="audi">Marketing & Communications</option>
                      <option value="audi">Mining & Resiurces & Energy</option>
                      <option value="audi">Real Estate & Property</option>
                      
                      <option value="audi">Retail & Consumer Products</option>
                      <option value="audi">Sales</option>
                      <option value="audi">Science & Technology</option>
                      <option value="audi">Sport & Recreation</option>
                      <option value="audi">Trades & Service</option>
                      <option value="audi">Other</option>

                    </select>
                    <label>Industry:</label>
                  </div>
                </div>
                <div class="employer-form-item">
                  <textarea class="" name="message" required></textarea>
                  <label>Message:</label>
                </div>
                <div className="form-buttons">
                  <div className="submit-btn">
                    <button>Submit</button>
                  </div>
                  <div className="call-btn">
                    <button>
                      <IoIosCall
                        style={{
                          width: "1rem",
                          height: "1rem",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "0.5rem",
                          paddingTop: "0.2rem",
                        }}
                      />
                      Schedule a call
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Healthplan;
