import React from "react";
import collage from '../../assets/newimages/collage.png';
import "./trust.css";

const Trust = () => {
  return (
    <div className="sb__coach-div">
      <div className="sb__coach-main section__padding">
        <div className="trust-title">
          <h1>Expert Information You Can Trust</h1>
        </div>

        <div className="sb__coach-subtitle trust-row-div">
          <div className="trust-para">
            <p>
              Founder Mache Seibel, MD, NCMP is a leading authority on menopause
              who has helped over 10,000 women regain control of their lives and
              work. He spent 25 years as a member of the BIDMC/Harvard Medical
              School faculty. Dr. Seibel is a recipient of five national awards
              for patient education including the North American Menopause
              Society’s Media Award for expanding the knowledge and
              understanding of menopause.
            </p>
          </div>
          <div className="trust-img">
            <img src={collage} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trust;
