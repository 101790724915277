import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonial.css";
import React from "react";
import Carousel from "./carousel";
import testimonialimg from "../../assets/testimonial-img.png";

const Testimonial = ({ carousel }) => {
  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseonhover: true,
  };
  return (
    <>
      <div className="sb__testimonial-div">
        <div className="sb__testimonial-main section__padding">
          <div className="sb__testimonial-title">
                <h1>Testimonials</h1>
          </div>

          <div className="imgslider">
              
            <Slider {...settings}>
              {/*     {carousel.map((item) => (
                <div key={item.id}>
                  <img src={item.src}  alt={item.alt} />
                </div>
              ))} */}
              <div>
                <Carousel
                  content="Thank you so very much for sharing your wonderful songs and energy with us. I know everyone enjoyed your songs—very relevant lyrics! You certainly helped make Upstaging Lung Cancer’s Fundraiser a huge success. Hope you will join us again next year."
                  client="Hildy Grossman"
                />
              </div>
              <div>
                <Carousel
                  content="Everyone so enjoyed your talk and I can’t wait to share some of your wonderful songs and insight with my patients. Again, thanks for sharing."
                  client="Cary Perry, MD"
                />
              </div>
              <div>
                <Carousel
                  content="Your presentation at the Georgia OB/GYN Society was just wonderful! They saved the best for last! Thank you for all the inspiration. I am looking forward to making use of your ideas and enthusiasm."
                  client="Mardi Schaufler, MD"
                />
              </div>
              <div>
                <Carousel
                  content="Music makes people more receptive to learning and helps increase retention. HealthRock is a uniquely brilliant format for sharing health messages."
                  client="Mehmet Oz, MD"
                />
              </div>

              <div>
                <Carousel
                  content="Your presence and your message were very important for our youth. As I mentioned in an interview, your message served to emphasize what was important about the events, in the midst of all of the activities that took place. We’re looking forward to many more collaborations in the future."
                  client="Dr. Melanie Johnson"
                />
              </div>
              <div>
                <Carousel
                  content="Thank you so much for the wonderful performance you have at Children’s Hospital Boston on June 11. It was truly a special treat, one that attracted attendees from across the hospital community. We received a great deal of positive feedback.

                  We hope there will be opportunities for future collaborations with Children’s Hospital. Best of luck with your musical enterprise."
                  client="S. Jean Emans, MD and Maxine Milstein, MBA"
                />
              </div>

              <div>
                <Carousel
                  content="Thank you so much for doing such a terrific job creating the Vivelle-Dot song. The team LOVED IT!!! They gave you a standing ovation!!! Your talent is such a true and unique gem. We look forward to looking with you the next time we need a fun and creative way to get our message out."
                  client="Stephanie Lamenta"
                />
              </div>
              <div>
                <Carousel
                  content="Students and community here just haven’t stopped talking about your DocRock entertainment. We sure enjoyed you here, and I hope that when you come back home you’ll be able to do a few more programs for our other students that missed out. It’s been a pleasure working with you."
                  client="Deborah E. Laine"
                />
              </div>

              <div>
                <Carousel
                  content="Thanks so much for participating in the Urban Zen Foundation/HealthCorps’ FitTown program on Childhood Obesity that we held at Donna Karan’s Urban Zen Center. The attendees loved your songs and stories and they all started clapping when you sang the Phat Fat Rap. It was a lot of fun! People have been talking about that for a while. We look forward to having you participate again in future programs."
                  client="Rachel P. Goldstein"
                />
              </div>
              <div>
                <Carousel
                  content="…we are extremely pleased with the development of our STEM song. You took our thoughts and made them come alive in song. The lyrics express the STEM content as well as the message that we wanted to convey and the music is most appropriate for our target audience. Thank you so much and we look forward to a very productive collaboration in the future!"
                  client="Clifford W. Houston, Ph.D."
                />
              </div>

              <div>
                <Carousel
                  content="Dr. Mache served as an expert on my company’s behalf in a legal proceeding, a matter in which we prevailed. The judge noted in his decision that Dr. Mache’s testimony was more credible and persuasive than the opposition’s medical expert. Dr. Mache did this, I believe, by being thoroughly prepared and reasonable in his positions. He also happens to come across as honest, likeable and can explain complex ideas well."
                  client="Andrew J. Lane"
                />
              </div>
              <div>
                <Carousel
                  content="You certainly had fifty people engaged and informed…with smiles all around. Thanks so much for coming to speak to us and for being so generous with your time and attention to our people. Hope they didn’t keep you there all night.

                  May your health education program with music thrive and reach a wide audience."
                  client="Nancy R. Winsten"
                />
              </div>

              <div>
                <Carousel
                  content="I made a point to be at your presentation and it was the highlight of the meeting for me. Thank you so very much for your contributions to a very successful meeting. You certainly do put the ‘communications’ in Health and Science!"
                  client="Ron Sokolowski"
                />
              </div>
              <div>
                <Carousel
                  content="On behalf of the Temple Emanuel L’Chaim Senior group, I want to thank you for your performance at their recent meeting on Tuesday, April 27th. Everyone who attended the meeting said ‘it was one of the most enjoyable programs of the year.’"
                  client="Doris Lelchook"
                />
              </div>
            </Slider>
            <div className="sb__testimonial-img">
              <img src={testimonialimg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Testimonial;
