import React from "react";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import "./terms.css";

const Terms = () => {
  return (
    <div>
      <div className="sb_terms">
        <div className="section__padding">
          <div className="sb_terms-title">
            <h2>Terms Of Service</h2>
          </div>
          <div className="sb_terms-des">
            <p>
              These terms and conditions (“Terms”) apply to any website,
              including [URL], (“Sites”) and any web-based, mobile device or
              tablet application (“Applications”) owned or operated by Second
              Bloom HQ Inc. (“Second Bloom”) that links to these Terms, and any
              related software, content, programs, services, materials or
              offerings (collectively, the “Services”).<br></br>
              <br></br>
              By using or accessing the Services, you are agreeing to be bound
              by these Terms and you represent and warrant that you that you are
              at least 18 years of age and you are not located in, do not reside
              in, and will not access or use the Services in the European Union.
              If you do not agree with any of these Terms, or if you are or are
              legally unable to agree to these Terms, you are prohibited from
              using or accessing the Services. All references to “we,” “our,”
              and “us” in these Terms refer to Second Bloom.<br></br>
              <br></br>
              For information on how we use and protect the information you may
              provide through use of the Services, please read our privacy
              policy at URL (<Link to="/privacy">“Privacy Policy”</Link>) and
              Section 5 below. Your use of the Services constitutes your
              agreement with our Privacy Policy.
              <br></br>
              <br></br>
              We may periodically change these Terms without prior notice, so
              please check back from time to time. Your continued use of the
              Services after changes are posted constitutes your continued
              agreement to the then-current Terms.<br></br>
              If you receive access to the Services through your employer or
              another sponsoring entity (
              <span className="terms_bold">“Sponsor”</span>), such that your
              Sponsor pays fees directly to us, you may be referred to herein as
              a (<span className="terms_bold">“Sponsored User”</span>).<br></br>
              <br></br>
              <span className="terms_bold">1. User Registration</span>
              <br></br>
              In order to access our health and wellness related services
              through our mobile device, tablet or web-based Applications, you
              must first register with us by creating a user account (a{" "}
              <span className="terms_bold">“User Account”</span>). In order to
              create a User Account, you must select a user name and password
              and, for certain functionality, you may also be required to
              provide additional information as indicated at the time of
              registration as further described in Section 5. All of this
              information comprises your{" "}
              <span className="terms_bold">“User Account Information”</span>.
              <br></br>
              <br></br>
              You agree that you will provide us only with accurate User Account
              Information and notify us promptly if any such User Account
              Information changes. You also acknowledge that you are responsible
              for maintaining the privacy and security of your User Account name
              and password. You may not share access to your User Account for
              any reason. If you believe your User Account privacy or security
              has been compromised you must notify us immediately. Our use of
              your User Account Information is subject to our{" "}
              <Link to="/privacy">Privacy Policy</Link> and these Terms.
              <br></br>
              <br></br>
              You represent that you are not located in a country that is
              subject to a U.S. Government embargo, or that has been designated
              by the U.S. Government as a “terrorist supporting” country and
              that you are not listed on any U.S. Government list of prohibited
              or restricted parties.<br></br>
              <br></br>
              We may retain User Account Information indefinitely in order to
              use it for ensuring compliance with these Terms and any applicable
              legal requirements, in order to modify and improve our Services,
              as well as for any other purposes authorized by these Terms or our
              Privacy Policy.<br></br>
              <br></br>
              We may revise the Services at any time, in our sole discretion, by
              posting descriptions of such revised Services on our website. We
              may discontinue the Services and/or your access to the Services at
              any time. Full use of the Services is dependent upon your use of
              adequate internet access, software and hardware. You are
              responsible for securing such access, software and equipment, and
              your failure to do so could affect your access to, and the
              functioning of, the Services.<br></br>
              <br></br>
              <span className="terms_bold">2. License Grant and Scope</span>
              <br></br>
              Subject to and conditioned upon your strict compliance with these
              Terms, we hereby grant you a non-exclusive, non-transferable,
              non-sublicensable, limited license during the term hereof to
              access, download, install and use the Services solely for your
              personal, non-commercial use. Notwithstanding anything to the
              contrary in these Terms, with respect to Sponsored Users, the
              license granted in this Section shall immediately terminate upon
              termination of our agreement with your Sponsor.<br></br>
              <br></br>
              <span className="terms_bold">3. Use Restrictions</span>
              <br></br>
              You may use the Services only for purposes that are legal, proper
              and in accordance with these Terms. By way of example, and not as
              a limitation, you agree that when using the Services, you will
              not:
              <br></br>
              <br></br>
              1. copy, modify, translate, adapt or otherwise create derivative
              or improvements, whether or not patentable, of the Services or any
              part thereof;<br></br>
              2. combine the Services or any part thereof with, or incorporate
              the Services or any part thereof in, any other programs;<br></br>
              3. reverse engineer, disassemble, decompile, decode or otherwise
              attempt to derive or gain access to the source code of the
              Services or any part thereof;<br></br>
              4. remove, delete, alter or obscure any trademark, copyright,
              patent or other intellectual property or proprietary rights
              notices provided on or with the Services, including any copy
              thereof;
              <br></br>
              5. rent, lease, lend, sell, sublicense, assign, distribute,
              publish, transfer or otherwise make available the Services, or any
              features or functionality of the Services, to any third party for
              any reason;
              <br></br>
              6. use the Services in violation of any law, regulation or rule;
              <br></br>
              7. remove, disable, circumvent, or otherwise create or implement
              any workaround to any copy protection or security features in the
              Services; or<br></br>
              8. use the Services for purposes of competitive analysis of the
              Applications or Services, the development of a competing
              application, website product or service or any other purpose that
              is to our commercial disadvantage.<br></br>
              <br></br>
              <span className="terms_bold">
                4. Monitoring, Audit and Expectations of Privacy
              </span>
              <br></br>
              The Services are subject to monitoring by us at all times to
              ensure proper functioning, to prevent unauthorized use and
              violations of laws and regulations, to deter criminal activity,
              and for our other business purposes. As a user of the Services,
              you hereby acknowledge and agree that any information placed
              shared through use of the Services is subject to monitoring and is
              not subject to any expectation of privacy from us beyond the
              obligations of applicable law, these Terms and our{" "}
              <Link to="/privacy">Privacy Policy</Link>. We reserve the right to
              disclose to law enforcement authorities information concerning
              your use of, and/or access to, the Services.<br></br>
              <br></br>In addition to any monitoring of your use of the Services
              to ensure your compliance with this Agreement, we also may, in our
              sole discretion, audit your use of the Services within twelve (12)
              months after the end of the Term to ensure you have ceased use of
              the Services. You shall fully cooperate with our personnel
              conducting such audits. We shall only examine information directly
              related to your use of the Services.<br></br>
              <br></br>
              <span className="terms_bold">
                5. Collection and Use of Information
              </span>
              <br></br>
              You acknowledge that when you access the Services, we may use
              automatic means (including, for example, cookies and web beacons)
              to collect information about you and about your use of the
              Services. You also may be required to provide certain information
              about yourself, including your personal health information and
              other similar personal information, as a condition to downloading,
              installing or using the Services or certain of their features or
              functionality, and the Services may provide you with opportunities
              to share information about yourself with others. By downloading,
              installing, using and providing information to or through the
              Services, you consent to all actions taken by us with respect to
              your information in compliance with our applicable policies and
              applicable law. You specifically acknowledge and agree that some
              or all of your information may be shared with third parties for
              purposes of billing, operations, or improvement of the Services.
              Your information will not be shared for marketing or advertising
              purposes. You specifically acknowledge and agree that we may
              record some conversations between you and staff or support team
              members for quality assurance and product improvement purposes.
              <br></br>
              For more information about our use and collection of information
              about you, please see our{" "}
              <Link to="/privacy">Privacy Policy</Link>.<br></br>
              <br></br>
              <span className="terms_bold">6. Submissions</span>
              <br></br>
              You represent and warrant that you own or have the right to all
              information, ideas, feedback, suggestions, materials, photos, and
              other content (<span className="terms_bold">“Submissions”</span>)
              that you post to or submit through the Services, and you hereby
              grant us a non-exclusive, transferable, sublicensable, worldwide,
              irrevocable, royalty-free license to use, copy, modify, translate,
              create derivative works from, and distribute such Submissions. You
              further represent and warrant that you will not upload, post,
              email or otherwise transmit or make available any materials that
              (a) are unlawful, harmful, threatening, abusive, harassing,
              tortious, defamatory, vulgar, obscene, libelous, invasive of
              another's privacy, hateful, or racially, ethnically or otherwise
              objectionable; (b) infringe upon any patent, trademark, trade
              secret, copyright, right of privacy or publicity or other
              proprietary rights of any party; and/or (c) contain software
              viruses or any other computer code, files or programs designed to
              interrupt, destroy or limit the functionality of any computer
              software or hardware or telecommunications equipment.<br></br>
              <br></br>
              Your right to use and/or access the Services depends on your
              compliance with the standards and conduct guidelines set forth
              above. If you fail to adhere to these standards and guidelines, or
              any part of these Terms, we may terminate, in our sole discretion,
              your use of, or access to the Services, regardless of whether our
              agreement with your Sponsor remains in effect.<br></br>
              <br></br>
              <span className="terms_bold">7. Updates</span>
              <br></br>
              We may from time to time in our sole discretion develop and
              provide updates to the Services, which may include upgrades, bug
              fixes, patches and other error corrections and/or new features
              (collectively, <span className="terms_bold">“Updates”</span>).
              Updates may also modify or delete in their entirety certain
              features and functionality of the Services. You agree that we have
              no obligation to provide any Updates or to continue to provide or
              enable any particular features or functionality of the Services.
              You may be required to download and install Updates as a condition
              to your continued use of and access to the Services.<br></br>
              <br></br>
              <span className="terms_bold">
                8. Intellectual Property Rights
              </span>
              <br></br>
              Except Third Party Materials (defined below), all content and
              functionality of the Services, including text, modules, videos,
              graphics, logos, icons, and images and the selection and
              arrangement thereof, in addition to any underlying concepts,
              know-how, tools, frameworks, software, algorithms, models, and
              industry perspectives, is the exclusive property of either us or
              our licensors and, to the extent applicable, is protected by U.S.
              and international copyright laws. The trademarks, service marks,
              designs, and logos (collectively, the{" "}
              <span className="terms_bold">“Trademarks”</span>) displayed in
              connection with Services are the registered and unregistered
              Trademarks of us and our licensors. We retain all right, title and
              interest in and to all usage data, statistical data or aggregated
              and anonymized data collected, extracted or derived from the
              Services.<br></br>
              <br></br>You shall promptly notify us if you become aware of any
              infringement of our intellectual property with respect to the
              Services, and you agree to fully cooperate with us, at our sole
              expense, in any legal action taken by us against a third party
              with respect to such infringement.<br></br>
              <br></br>
              <span className="terms_bold">9. Fees</span>
              <br></br>
              FOR SPONSORED USERS ONLY: All applicable fees for access to and
              use of the Services will be paid by your Sponsor pursuant to a
              separate agreement between your Sponsor and us. We may offer you
              additional products or services that may require you to pay a fee
              to us and any additional services and fees will be subject to a
              separate agreement between you and us.<br></br>
              <br></br>
              FOR ALL NON-SPONSORED USERS: You agree to pay, and you hereby
              authorize our third party payment processor to charge you using
              your selected payment method, all subscription fees-set forth at
              listed in our then current fee schedule, which we may update or
              revise from time-to-time, in our sole discretion. Subscriptions
              are for a minimum of six months, payable 100% in advance or on a
              monthly in advance basis commencing on the day you sign up for the
              Services and continuing every 30 days thereafter. Fees shall be
              due regardless of whether you use the Services. You are
              responsible for providing complete and accurate billing and
              contact information to us. Please note that your subscription will
              renew automatically unless you cancel prior to renewal by
              providing written notice to our support team.<br></br>
              <br></br>
              <span className="terms_bold">10. Term and Termination</span>
              <br></br>
              These Terms will terminate automatically upon any termination of
              your right to use the Services; provided, however, that any
              license granted to you by us and any provision of these Terms or
              the Privacy Policy necessary to enforce or exercise your or our
              rights or obligations hereunder shall survive the termination of
              these Terms.
              <br></br>
              FOR SPONSORED USERS ONLY: These Terms and your access to the
              Services will terminate if the agreement between us and your
              Sponsor is terminated or expires or in the event you are no longer
              eligible to use the Services under the terms of your Sponsor’s
              agreement with us.<br></br>
              <br></br>
              FOR NON SPONSORED USERS: Please see Section 9 for information on
              how to cancel your subscription to the Services. Upon termination
              of these Terms for any reason, you shall remit to us all
              outstanding fees owed by you to us, and you shall not be entitled
              to any refund of fees (including any partial refund), except as
              may be required by applicable law.<br></br>
              <br></br>
              <span className="terms_bold">11. Third Party Materials</span>
              <br></br>
              The Services may display, include or make available third-party
              content (including your Submissions, Submissions by other users,
              data, information, applications and other products, services
              and/or materials) (
              <span className="terms_bold">“Third Party Materials”</span>). You
              acknowledge and agree that we are not responsible for Third Party
              Materials, including their accuracy, completeness, timeliness,
              validity, copyright compliance, legality, decency, quality or any
              other aspect thereof. We do not assume and will not have any
              liability or responsibility to you or any Third Party for any
              Third Party Materials.<br></br>
              <br></br>
              In addition, our Services may be made available to you through
              third party websites, applications or platforms operated by a
              third party (a{" "}
              <span className="terms_bold">“Mobile Platform Provider”</span>),
              for example through a mobile app store. The websites, applications
              and platforms of these Mobile Platform Providers are subject to
              separate and independent terms of use and privacy policies, which
              apply to your use of their websites, applications and/or
              platforms, and any related services provided by the Mobile
              Platform Provider, with which you agree to comply and which are
              not subject to our control, and which we recommend you review
              carefully. Without limiting the foregoing, you acknowledge that no
              Mobile Platform Provider has any control over, nor makes any
              warranty or shall have any liability to you for, the Services, and
              any claim or dispute by you in connection therewith shall be
              governed by these Terms. Where required by our agreement with such
              Mobile Platform Provider, such Mobile Platform Provider is a third
              party beneficiary of this paragraph of these Terms and may enforce
              these Terms against you as a third party beneficiary thereof.
              <br></br>
              <br></br>
              <span className="terms_bold">12. CONSULT WITH YOUR DOCTOR</span>
              <br></br>
              THE SERVICES PROVIDE WEIGHT LOSS MANAGEMENT AND RELATED
              INFORMATION AND CONTENT INTENDED ONLY TO ASSIST USERS IN THEIR
              PERSONAL WEIGHT LOSS AND WELLNESS IMPROVEMENT EFFORTS. WE ARE NOT
              A MEDICAL PROVIDER AND NEITHER WE, NOR OUR STAFF, THROUGH THE
              SERVICES OR OTHERWISE, PROVIDE MEDICAL ADVICE, TREATMENT OR
              DIAGNOSIS. NOTHING CONTAINED IN THE SERVICES SHOULD BE CONSTRUED
              AS MEDICAL ADVICE, TREATMENT OR DIAGNOSIS. USE OF THE SERVICES
              SHOULD NOT BE INTERPRETED AS A SUBSTITUTE FOR PHYSICIAN
              CONSULTATION, EVALUATION, OR TREATMENT. YOU ARE URGED AND ADVISED
              TO SEEK THE ADVICE OF A PHYSICIAN BEFORE BEGINNING ANY WEIGHT LOSS
              EFFORT, EXERCISE REGIMEN OR RELATED WELLNESS MEASURES OR
              INITIATIVES, INCLUDING WITHOUT LIMITATION THE USE OF THE SERVICES.
              <br></br>
              <br></br>
              <span className="terms_bold">13. Disclaimer of Warranties</span>
              <br></br>
              THE SERVICES ARE PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND
              DEFECTS AND WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT
              PERMITTED UNDER APPLICABLE LAW, WE, ON OUR OWN BEHALF AND ON
              BEHALF OF OUR LICENSORS AND SERVICE PROVIDERS, INCLUDING MOBILE
              PLATFORM PROVIDERS, EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER
              EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE
              SERVICES, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, COMPLETENESS, ACCURACY, TITLE
              AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE
              OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE.
              WITHOUT LIMITATION OF THE FOREGOING, WE PROVIDE NO WARRANTY OR
              UNDERTAKING, AND MAKE NO REPRESENTATION OF ANY KIND THAT THE
              SERVICES WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
              RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER APPLICATIONS,
              SOFTWARE, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET
              ANY PERFORMANCE OR RELIABILITY STANDARDS, BE ERROR FREE, ARE
              SECURE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
              <br></br>
              <br></br>
              <span className="terms_bold">14. Limitation of Liability</span>
              <br></br>
              IN NO EVENT WILL WE OR ANY OF OUR LICENSORS OR SERVICE PROVIDERS,
              INCLUDING MOBILE PLATFORM PROVIDERS, BE LIABLE TO YOU OR ANY THIRD
              PARTY FOR ANY USE, INTERRUPTION, DELAY OR INABILITY TO USE THE
              SERVICES, LOST REVENUES OR PROFITS, DELAYS, INTERRUPTION OR LOSS
              OF SERVICES, BUSINESS OR GOODWILL, LOSS OR CORRUPTION OF DATA,
              LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE FAILURE, MALFUNCTION
              OR SHUTDOWN, FAILURE TO ACCURATELY TRANSFER, READ OR TRANSMIT
              INFORMATION, FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION,
              SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY
              INFORMATION OR BREACHES IN SECURITY, OR FOR ANY CONSEQUENTIAL,
              INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES,
              WHETHER ARISING OUT OF OR IN CONNECTION WITH THE SERVICES
              REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR
              NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br></br>
              <br></br>
              IN NO EVENT WILL OUR AND OUR LICENSORS’ AND SERVICE PROVIDERS’,
              INCLUDING APPLICABLE MOBILE PLATFORM PROVIDERS, COLLECTIVE
              AGGREGATE LIABILITY UNDER OR IN CONNECTION WITH THE SERVICES,
              UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT,
              TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE,
              EXCEED THE TOTAL AMOUNT PAID BY YOU TO US PURSUANT TO THESE TERMS
              OR FOR THE SERVICES. WITHOUT LIMITING THE FOREGOING, WE HAVE NO
              RESPONSIBILITY FOR ANY TRAINING PROGRAMS, CONSULTATIONS OR
              INFORMATION THAT YOU LEARN ABOUT THROUGH USE OF THE SERVICES AND
              YOU AGREE THAT YOUR USE OF THE APPLICATION AND SERVICES IS
              ENTIRELY AT YOUR OWN RISK. WHEN USING THE SERVICES, YOU REMAIN
              RESPONSIBLE FOR EXERCISING GOOD JUDGMENT AND COMMON SENSE.
              <br></br>
              <br></br>
              YOU ACKNOWLEDGE AND AGREE THAT THE LIMITATIONS SET FORTH IN THIS
              SECTION 14 FORM A FUNDAMENTAL PART OF THIS AGREEMENT AND THESE
              LIMITATIONS SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT
              FAIL OF THEIR ESSENTIAL PURPOSE.<br></br>
              <br></br>
              <span className="terms_bold">15. Indemnification</span>
              <br></br>
              You agree to indemnify, defend and hold harmless us, our
              affiliates, and our and their officers, directors, employees,
              agents, affiliates, successors and assigns from and against any
              and all losses, damages, liabilities, deficiencies, claims,
              actions, judgments, settlements, interest, awards, penalties,
              fines, costs, or expenses of whatever kind, including reasonable
              attorneys' fees, arising from or relating to your use or misuse of
              the Services or your Submissions. Furthermore, you agree that we
              assume no responsibility for the Submissions or any other content
              you submit, use or make available through the Services. We reserve
              the right to assume control of the defense of any third party
              claim that is subject to your indemnification, in which case you
              will cooperate with us in asserting any available defenses.
              <br></br>
              <br></br>
              <span className="terms_bold">16. Export Regulation</span>
              <br></br>
              The Services may be subject to US export control laws, including
              the US Export Administration Act and its associated regulations.
              You shall not, directly or indirectly, export, re-export or
              release the Services or make the Services accessible from, any
              jurisdiction or country to which export, re-export or release is
              prohibited by law, rule or regulation. You shall comply with all
              applicable federal laws, regulations and rules, and complete all
              required undertakings (including obtaining any necessary export
              license or other governmental approval), prior to exporting,
              re-exporting, releasing or otherwise making the Services available
              outside the US.<br></br>
              <br></br>
              <span className="terms_bold">17. DMCA Claims</span>
              <br></br>
              If you are an intellectual property rights owner or an agent
              thereof and believe that any content posted on or through the
              Services infringes upon your intellectual property rights, you may
              submit a notification pursuant to the Digital Millennium Copyright
              Act (“DMCA”) by providing the following information in writing
              (see 17 U.S.C. §512(c)(3) for further detail):<br></br>
              <br></br>
              <ul>
                <li>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </li>

                <li>
                  Identification of the work claimed to have been infringed, or,
                  if multiple works at a single online site are covered by a
                  single notification, a representative list of such works at
                  that site;
                </li>

                <li>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled and
                  information reasonably sufficient to permit the service
                  provider to locate the material;
                </li>

                <li>
                  Information reasonably sufficient to permit the service
                  provider to contact you, such as an address, telephone number,
                  and, if available, an electronic mail;
                </li>

                <li>
                  A statement that you have a good faith belief that use of the
                  material in the manner complained of is not authorized by the
                  owner, its agent, or the law; and
                </li>

                <li>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                </li>
              </ul>
              <br></br>
              <br></br>
              Such information should be sent to
              <span className="terms_bold">
                {" "}
                customer.support@secondbloomhealth.com
              </span>
              . You acknowledge that if you fail to comply with all of the
              requirements set forth above, your DMCA notice may not be valid.
              <br></br>
              <br></br>
              If you have had your content removed under the above take-down
              procedure and believe that the content is not infringing, you may
              send a counter-notice containing the following information to
              <span className="terms_bold">
                {" "}
                customer.support@secondbloomhealth.com
              </span>
              :<br></br>
              <br></br>
              <ul>
                <li>Your physical or electronic signature;</li>
                <li>
                  Identification of the content that has been removed or to
                  which access has been disabled and the location at which the
                  content appeared before it was removed or disabled;
                </li>
                <li>
                  A statement that you have a good faith belief that the content
                  was removed or disabled as a result of mistake or a
                  misidentification of the content; and
                </li>
                <li>
                  Your name, address, telephone number, and e-mail address, a
                  statement that you consent to the jurisdiction of the federal
                  court in Washington, D.C., and a statement that you will
                  accept service of process from the person who provided
                  notification of the alleged infringement.
                </li>
              </ul>
              <br></br>
              <br></br>
              If a counter-notice is received by us, we may send a copy of the
              counter-notice to the original complaining party informing that
              person that it may replace the removed content or cease disabling
              it in ten (10) business days. Unless the copyright owner files an
              action seeking a court order against the content provider, member
              or user, the removed content may be replaced, or access to it
              restored, in ten (10) to fourteen (14) business days or more after
              receipt of the counter-notice, at our sole discretion.<br></br>
              <br></br>
              <span className="terms_bold">18. Miscellaneous</span>
              <br></br>
              1. PLEASE CAREFULLY READ THE FOLLOWING SECTION, WHICH REQUIRES YOU
              TO ARBITRATE CERTAIN DISPUTES AND CLAIMS YOU MAY HAVE WITH US, AND
              IT LIMITS THE MANNER IN WHICH YOU CAN SEEK REMEDIES FROM US. You
              agree that all disputes, claims, actions or causes of actions
              between you and us arising from or relating to these Terms or the
              Services (<span className="terms_bold">“Disputes”</span>) shall be
              subject to the arbitration procedure described below.<br></br>
              <br></br>
              2. You and we agree all Disputes shall be resolved through final
              and binding arbitration, except as expressly set forth otherwise
              in these Terms. You can decline this agreement to arbitrate by
              contacting{" "}
              <span className="terms_bold">
                customer.support@secondbloomhealth.com
              </span>{" "}
              within 30 days of first using the Services, stating your full name
              and that you decline to accept this arbitration agreement. The
              American Arbitration Association (
              <span className="terms_bold">“AAA”</span>) will administer the
              arbitration under its Commercial Arbitration Rules and the
              Supplementary Procedures for Consumer Related Disputes. The
              arbitration will take place in Norwalk, Connecticut or such other
              location to which we agree in writing. The AAA rules will govern
              payment of arbitration fees.<br></br>
              <br></br>
              3. You may only resolve Disputes with use on an individual basis,
              and you may not bring a claim as a plaintiff or a class member in
              a class, consolidated, or representative action. Class
              arbitrations, class actions, private attorney general actions and
              consolidation with other arbitrations are not permitted for any
              Disputes or for any other reason related to the Services<br></br>
              <br></br>
              4. These Terms are governed by and construed in accordance with
              the internal laws of the State of Connecticut without giving
              effect to any choice or conflict of law provision. In the event
              that the agreement to arbitrate set forth in this Section is found
              not to apply to you (or as otherwise expressly set forth in these
              Terms), any legal suit, action or proceeding arising out of or
              related to the Services shall be instituted exclusively in the
              federal courts of the United States or the courts of the State of
              Connecticut in each case located in Fairfield County, Connecticut,
              and each party irrevocably submits to the exclusive jurisdiction
              of such courts. you waive any and all objections to the exercise
              of jurisdiction over you by such courts and to venue in such
              courts. you and we each agree to waive our respective right to a
              jury trial.<br></br>
              <br></br>
              5. Regardless of any law to the contrary, any Dispute must be
              filed within one (1) year after such Dispute arose, or else that
              Dispute will be barred forever.<br></br>
              <br></br>
              6. These Terms are intended to supplement any other agreement or
              terms between us that may otherwise be applicable to the Services.
              In the event of any conflict or inconsistency between these Terms
              and any other agreement or terms, these Terms will take precedence
              and supersede any such other conflicting or inconsistent terms or
              agreements to the extent relating to the Services.<br></br>
              <br></br>
              7. A failure to exercise or enforce any right or provision of
              these Terms shall not constitute a waiver of such right or
              provision. If any provision of these Terms is found by a court of
              competent jurisdiction to be invalid, the parties nevertheless
              agree that the court should endeavor to give effect to the
              parties’ intentions as reflected in the provision, and the other
              provisions of these Terms remain in full force and effect.
              <br></br>
              <br></br>
              Please contact us at
              <span className="terms_bold"> customer.support@secondbloomhealth.com
              </span> if you have any questions about Terms of Service.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
