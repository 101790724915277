import React from "react";
import "./employer.css";
import orgimg from '../../assets/newimages/orgapagetwo_img.jpg';

import { IoIosCall } from "react-icons/io";
import Footer from "../footer/Footer";

const Employer = () => {
  return (
    <div>
      <div className="sb__employer section__padding">
        <div className="top-part">
          <div className="top-part-text organi-para">
            <h1>
              <span className="employees">Organizations</span>
              <br></br>Find out how the women in your organization and employees
              with women in their lives can feel healthier, happier and more
              productive with Second Bloom Health.
            </h1>
            <p>
              Women in perimenopause and menopause are one fourth of the
              workforce. However, less than 5% of organizations provide programs
              to support them. Second Bloom Health can lower healthcare costs,
              boost productivity, and improve retention.
            </p>
            <div  className="organi-para-blue">
            <p>One fourth of the workforce is dealing with menopause symptoms at work—it’s time to act with Second Bloom Health.</p>
            </div>
          </div>
          
        </div>

        <div className="middlepart">
          <div className="middlepart-list-img">
            <div className="middle-list">
              <h2>Menopause in the Workplace</h2>
                <p>
                  <ul>
                    <li><span className="landing-bold">87% of women</span> report one or more menopause symptoms at work</li>
                    <li><span className="landing-bold">6 in 10 women</span> say their symptoms impact their work</li>
                    <li><span className="landing-bold">1 in 6 have left</span> or considered leaving their jobs because of menopause symptoms</li>
                    <li><span className="landing-bold">6 in 10 take time off</span> due to menopause symptoms, and 20% take up to two weeks</li>
                    <li><span className="landing-bold">44% feel they don’t receive</span> enough menopause support at work</li>
                    <li><span className="landing-bold">3 of 4 would find menopause support</span> from an expert platform helpful</li>
                  </ul>
                </p>
            </div>
          <div className="top-part-img orgimg">
            <img src={orgimg} alt="" />
          </div>
         </div>
         
        </div>

        <div className="sb__employer-main">
          <div className="connect_aboveform">
            <h4>Connect with us</h4>
          </div>
          <div className="sb__employer-formbox">
            {/* <div className="employer-formbox-text-img">
              <h3>Get in touch with us</h3>
              <p>
                Learn what<br></br>Second Bloom HQ<br></br>can bring to your
                employer, members or clients.
              </p>
              <img src={cartoonimg} alt="" />
            </div> */}
            <div className="employer-formbox-textfield">
              <form>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <input type="text" name="firstname" required />
                    <label>First Name:</label>
                  </div>
                  <div class="employer-form-item">
                    <input type="text" name="lastname" required />
                    <label>Last Name:</label>
                  </div>
                </div>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <input type="text" name="jobtitle" required />
                    <label>Job Title:</label>
                  </div>
                  <div class="employer-form-item">
                    <input
                      type="tel"
                      name="phone"
                      minLength="10"
                      maxLength="10"
                      required
                    />
                    <label>Phone Number:</label>
                  </div>
                </div>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <input type="email" name="email" required />
                    <label>Work Email:</label>
                  </div>
                  <div class="employer-form-item">
                    <input type="text" name="companyname" required />
                    <label>Company Name:</label>
                  </div>
                </div>
                <div className="employer-formbox-inputs">
                  <div class="employer-form-item">
                    <select id="companysize" name="companysize" required>
                      <option value="volvo">company size</option>
                      <option value="volvo">1-10 employees</option>
                      <option value="saab">11-50 employees</option>
                      <option value="fiat">51-200 employees</option>
                      <option value="audi">201-500 employees</option>
                      <option value="fiat">501-1000 employees</option>
                      <option value="audi">1000+ employees</option>
                    </select>
                    <label>Company Size:</label>
                  </div>
                  <div class="employer-form-item">
                    <select id="industry" name="industry" required>
                      <option value="volvo">Industry</option>
                      <option value="saab">
                        Accounting, Administration & Office support
                      </option>
                      <option value="fiat">Advertising</option>
                      <option value="audi">Arts & Media</option>

                      <option value="audi">Banking & Financial Services</option>
                      <option value="audi">
                        Call Center & Customer Service
                      </option>
                      <option value="audi">
                        Community Services & Development
                      </option>
                      <option value="audi">Construction</option>
                      <option value="audi">Consulting & Strategy</option>
                      <option value="audi">Design & Architecture</option>
                      <option value="audi">Education & Training</option>
                      <option value="audi">Engineering</option>
                      <option value="audi">
                        Framing & Animals & Conservation
                      </option>
                      <option value="audi">Government & Defence</option>
                      <option value="audi">Healthcare & Medical</option>
                      <option value="audi">Hospitality & Tourism</option>
                      <option value="audi">
                        Human Resources & Recruitment
                      </option>
                      <option value="audi">
                        Information & Communication Technology
                      </option>
                      <option value="audi">Insurance</option>
                      <option value="audi">Legal</option>
                      <option value="audi">
                        Manufacturing & Transport & Logistics
                      </option>
                      <option value="audi">Marketing & Communications</option>
                      <option value="audi">Mining & Resiurces & Energy</option>
                      <option value="audi">Real Estate & Property</option>

                      <option value="audi">Retail & Consumer Products</option>
                      <option value="audi">Sales</option>
                      <option value="audi">Science & Technology</option>
                      <option value="audi">Sport & Recreation</option>
                      <option value="audi">Trades & Service</option>
                      <option value="audi">Other</option>
                    </select>
                    <label>Industry:</label>
                  </div>
                </div>
                <div class="employer-form-item">
                  <textarea class="" name="message" required></textarea>
                  <label>Message:</label>
                </div>
                <div className="form-buttons">
                  <div className="submit-btn">
                    <button>Submit</button>
                  </div>
                  <div className="call-btn">
                    <button>
                      <IoIosCall
                        style={{
                          width: "1rem",
                          height: "1rem",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "0.5rem",
                          paddingTop: "0.2rem",
                        }}
                      />
                      Schedule a call
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Employer;
