import React from "react";
import { Link } from "react-router-dom";
import "./whatwedo.css";
// import goalimg from "../../assets/goal-img.png";
// import achieveimg from "../../assets/achieve-img.png";
// import sleep from "../../assets/sleep2.png";
// import stress from "../../assets/stress.png";
// import nutrition from "../../assets/food.png";
// import exercise from "../../assets/exercise.png";
import Footer from "../footer/Footer";
import Philosophy from "../philosophy/philosophy";

const Whatwedo = () => {
  return (
    <div>
      <div className="newabout-div">
        <div className="newabout-vission">
          <h1>Our Vision</h1>
        </div>
        <div className="newabout-vission-subtitle">
          <p>
            A world where <span className="menopause">menopause is
            embraced</span> as a normal transition, and midlife women get the
            information, support, and respect they deserve from businesses and
            society
          </p>
        </div>

        <div className="newabout-mission">
          <h1>Our Mission</h1>
        </div>
        <div className="newabout-vission-subtitle">
          <p>
            To provide midlife women with the <span className="menopause">highest quality
            menopausal information and
            support</span> for a healthier, happier life and more positive, productive
            work experience
          </p>
        </div>
      </div>

      <Philosophy />
      {/* <div className="sb__whatwedo-ourgoal section__padding">
        <div className="sb__ourgoal-title">
          <p>
            <h2>Our goal</h2>is to support you as you learn more about the
            menopausal changes you may be experiencing that make you feel
            off-kilter, and to empower you to take action so you can get your
            life back in control - whatever that may look like for you.
          </p>
        </div>
        <div className="sb__ourgoal-img">
          <img src={goalimg} alt="" />
        </div>
      </div> */}
      <div className="sb__whatwedo-div">
        <div className="sb__howwedo">
          {/* <div className="sb__howwedo-title section__padding">
            <h2>How do we achieve our goal?</h2>
          </div> */}
          {/* <div className="sb__howwedo-subtitle section__padding">
            <div className="bluebox-text">
              <p>
                Educational content created by our Founder Dr. Mache Seibel, a
                leading menopause expert, who has helped over 10,000 women
                through their menopause journey.
              </p>
            </div>
            <div className="grey-text">
              <p>
                Understanding of what is happening to your body, what to expect,
                and what to do about the hormonal changes and symptoms that
                occur during perimenopause, menopause and beyond.
              </p>
            </div>
          </div> */}
          {/* <div className="sb__iconimg-part">
            <div className="icons-part">
              <div className="icons-part-text">
                <p>
                  Improve your menopause symptoms and be healthy by building
                  <br></br>
                  habits to manage the following holistic lifestyle areas :
                </p>
              </div>
              <div className="icons-part-icons">
                <div className="sleep">
                  <img src={sleep} alt="" />
                  <p>SLEEP</p>
                </div>
                <div className="stress">
                  <img src={stress} alt="" />
                  <p>STRESS</p>
                </div>
                <div className="nutrition">
                  <img src={nutrition} alt="" />
                  <p>NUTRITION</p>
                </div>
                <div className="exercise">
                  <img src={exercise} alt="" />
                  <p>EXERCISE</p>
                </div>
              </div>
            </div>
            <div className="img-part">
              <img src={achieveimg} alt="" />
            </div>
          </div> */}
          <div className="sb__whatwedo-textpart">
            {/* <div className="sb__whatwedo-textpart-title section__padding">
              <p>
                This takes time, patience, and repetition. We’re here to make it
                easier. We have lessons, articles, videos and activities that
                will help you figure out what those healthy habits look like for
                you.
              </p>
            </div> */}
            {/* <div className="sb__howwedo-subtitle section__padding">
              <div className="grey-text">
                <p>
                  You are not alone, and your coach is here to help you in every
                  step of the way.
                  One of the best ways to be successful in meeting your goals is
                  to engage with this program - that’s what leads to the most
                  significant changes.
                </p>
              </div>
              <div className="bluebox-text">
                <p>
                  Your personal certified coach will check in with you via text,
                  email, calls or video conferences at a frequency that fits
                  your schedule and needs.
                </p>
              </div>
            </div> */}
          </div>

          <div className="sb__textlist-part section__padding">
            {/* <div className="sb__textlist-title">
              <p>
                Engagement looks different for everyone. Second Bloom Health
                provides many pathways to easily engage. Get results by spending
                only 15 minutes per day in activities that include:
              </p>
              <div className="engage-list">
                <p>
                  <ul>
                    <li>Texting your coach</li>
                    <li>Completing lessons and a wide range of activities</li>
                    <li>Exercising in brief sessions</li>
                    <li>Practicing relaxation techniques</li>
                    <li>Connecting with others in the community</li>
                    <li>Logging your meals (done via a photo of your plate)</li>
                    <li>
                      Diving deeper into discussions on topics important to you
                    </li>
                    <li>Participating in expert-led educational sessions</li>
                  </ul>
                </p>
              </div>
            </div> */}


            {/* <div className="sb__textlist-list">
              <Verticaltimeline />
            </div>
            <div className="sb__textlist-title">
              <p>
                Be patient, and be kind to yourself. It takes time and practice!
                Your journey won’t always be smooth - after all, we’re talking
                about a time in life know as “the change.” So you should expect
                to have some ups and downs.
                
              </p>
            </div> */}
{/* 
            <div className="sb__textdown-middle">
              <p>
                Right now, you may feel powerless, like this is just happening
                to you, and you have no control over your body, mind, or
                emotions. It’s okay if you feel that way, but know that you’re
                not alone! In fact, by joining this program, you’re joining a
                community of women who are going through similar struggles. Like
                you, these women have taken the first step toward making a
                change!
                Your personal certified coach will check in with you via text,
                email, calls or video conferences at a frequency that fits your
                schedule and needs.
              </p>
            </div>

            <div className="sb__whatwedo-textpart-title section__padding">
              <p>
                So take a moment to pause and congratulate yourself for taking
                that first step. You’re here! And we’re here for you, every step
                of the way.
                Take a moment to pause and congratulate yourself for taking
                that first step. You’re here! And we’re here for you, every step
                of the way.
              </p>
            </div> */}

            <div className="findmore-btn">
            <Link to="/faq"><button>Find out more</button></Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Whatwedo;
