import React from "react";
import "./fitness.css";
import guided_excer from "../../assets/newimages/fitimg.jpg";

const Fitness = () => {
  return (
    <div className="sb__fitness-div">
      <div className="sb__fitness-main section__padding">
        <div className="sb__fitness-title">
          <h1>
            Take Care of <span className="bold">All of You</span>
          </h1>
        </div>

        <div className="sb__fitness-subtitle">
          <div className="sb__fitness-img">
            <img src={guided_excer} alt=""></img>
          </div>
          <div className="sb__fitness-para">
            <p>
              <ul>
                <li className="fitli"><span className="fitnessbold">Information</span> to help you from perimenopause through menopause.</li>
                <li className="fitli"><span className="fitnessbold">Guided exercises</span> that fit your schedule.</li>
                <li className="fitli"><span className="fitnessbold">Nutritional guidance</span> to help you eat to defeat menopause.</li>
                <li className="fitli"><span className="fitnessbold">Stress-busting tools</span> that help control hot flashes, sleep and mood.</li>
                <li className="fitli"><span className="fitnessbold">1:1 certified coaches</span> create customized menopause wellness plans.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fitness;
