import React from "react";
import "./coach.css";
import coach from "../../assets/newimages/coachnew2.jpg";

const Coach = () => {
  return (
    <div className="sb__coach-div">
      <div className="sb__coach-main section__padding">
        <div className="sb__coach-title">
          <h1>Personalized 1:1 Coaching</h1>
        </div>

        <div className="sb__coach-subtitle">
          <div className="sb__coach-para">
            <p>
             Overwhelmed? Not sure what to do?<br></br><br></br>
              <span className="sb__coach-para-purple">
              Our certified coaches are here to help with guidance, support and the tools you need.
              </span>
            </p>
          </div>
          <div className="sb__coach-img">
            <img src={coach} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coach;
