import React, { useState } from "react";
import Footer from "../footer/Footer";
import { questions } from "../faq/accordian/api";
import Accordian from "./accordian/accordian";
import faqimg from '../../assets/newimages/faqimg.jpg';
import "./faq.css";
import { Link } from "react-router-dom";

const Faq = () => {
  const [data] = useState(questions);
  return (
    <>
      <div className="sb__faq-main section__padding">
        <div className="sb__faq-title">
          <h1>FAQ</h1>
          <p>
            Learn more about<br></br>Second Bloom Health<br></br>perimenopause and menopause
            care
          </p>
          <div className="faq-button">
            <p>STILL HAVE QUESTIONS?</p>
            <Link to="/individual"><button>Contact us</button></Link>
          </div>
          <div className="faq-img">
            <img src={faqimg} alt=''/>
          </div>
        </div>
        <div className="sb__faq-section">

        {data.map((item)=>{
            return <Accordian key={item.id} question={item.question} answer={item.answer} points={item.points}/>;
        })}

        </div>
      
      
    </div>
    <Footer/>
    </>
  );
};

export default Faq;
