import React from "react";
import "./individual.css";

import { IoIosCall } from "react-icons/io";
import indivimg from "../../assets/newimages/indivipage_img.jpg";
import Footer from "../footer/Footer";

const Individual = () => {
  return (
    <div>
      <div className="sb__individual-main section__padding">
        <div className="top-part individual-top">
          <div className="top-part-text">
            <h1>
              <span className="individuals">For Individuals</span>
              <br></br>Let Second Bloom Health help you become a partner in your
              healthcare
            </h1>
            <p>
              The physical and emotional changes that occur during perimenopause
              and menopause can leave you feeling out of sorts, with lots of
              questions about what is happening to your body.<br></br>
              <br></br>Second Bloom Health’s holistic perimenopause and
              menopause information and coaching support can help you get back
              in control.
            </p>
          </div>
          <div className="top-part-img indivimg">
            <img src={indivimg} alt="" />
          </div>
        </div>
        {/* <div className="sb__individual-title">
        <h1>Individual</h1>
        <p>Connect with us</p>
      </div> */}
        <div className="connect_aboveform">
          <h4>Connect with us</h4>
        </div>
        <div className="sb__individual-formbox">
          {/* <div className="formbox-text-img">
            <h3>For an Individual</h3>
            <p>
              Learn more about<br></br>Second Bloom Health<br></br>and our
              groundbreaking program for perimenopause and menopause care
            </p>
            <img src={indiformimg} alt="" />
          </div> */}
          <div className="formbox-textfield">
            <form>
              <div className="formbox-inputs">
                <div class="form-item">
                  <input type="text" name="name" required />
                  <label>Name:</label>
                </div>
                <div class="form-item">
                  <input type="text" name="email" required />
                  <label>Email:</label>
                </div>
              </div>
              <div class="form-item">
                <textarea class="" name="message" required></textarea>
                <label>Message:</label>
              </div>
              <div className="form-buttons">
                <div className="submit-btn">
                  <button>Submit</button>
                </div>
                <div className="call-btn">
                  <button>
                    <IoIosCall
                      style={{
                        width: "1rem",
                        height: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "0.5rem",
                        paddingTop: "0.2rem",
                      }}
                    />
                    Schedule a call
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Individual;
