export  const questions=[
    {
        id:1,
        question: "What is Second Bloom Health?",
        points:[],
        answer: "Second Bloom Health (SBH) provides employers with a proven, scalable, HIPPA compliant, easy-to-use platform with Digital Support and assigned coaching staff to support women during their 7 to 10 year menopause journey. SBH is based on the work of Dr. Mache Seibel, who has helped over 10,000 women transition through their menopause journey. Dr. Seibel's program empowers women to get the information they need to become a partner in their healthcare. Together with their coach and participants, discover how to proactively make healthy choices and develop lifestyle choices to improve sleep and nutrition, include regular exercise and reduce stress to positively impact the journey through menopause.",
    },
    {
        id:2,
        question: "Is my health data protected from my employer?",
        points:[],
        answer: "Yes, Second Bloom HQ firmly believes in patients’ right to privacy. We’re fully HIPAA compliant and do not share any individualized or personally identifying data with any external party."
    },
    {
        id:3,
        question:"Where is Second Bloom Health available?",
        points:[],
        answer:"Second Bloom Health currently supports members in the United States and we’re working to expand our geographic reach to other countries. It is available to organizations and to individuals."
    },
    {
        id:4,
        question:"How much does Second Bloom Health cost?",
        points:[],
        answer:"Second Bloom Health is offered as an employee benefit, part of a health plan or a premium individual subscription with our Partner EMBR Labs.  As an employee benefit or part of your health plan means your employer or health plan provider covers the full cost of your membership.  As a premium individual subscription, you are responsible for paying for the program."
    },
    {
        id:5,
        question:"Will Second Bloom Health replace my yearly visit to the gynecologist?",
        points:[],
        answer:"Second Bloom Health is not meant to replace your annual gynecological appointment, but complements your in-person care with our digital platform and our certified menopause coaches. Information from your Program can be shared with your Health Care provider to enhance the care that you receive."
    },
    {
        id:6,
        question:"Does Second Bloom Health prescribe medication?",
        points:[],
        answer:"No, Second Bloom Health menopause coaches will not prescribe medications. Menopause is a very personal journey and every woman is different. Our menopause coaches meet you where you are—together, you’ll review your symptoms and concerns, and collaboratively build a plan right for you."
    },
    {
        id:7,
        question:"Does Second Bloom Health accept insurance?",
        points:[],
        answer:"Second Bloom Health does not currently accept insurance. We partner directly with employers, health plans and individuals to deliver menopause support."
    },
    {
        id:8,
        question:"How should I prepare for my Second Bloom Health coaching experience?",
        points:[],
        answer:"Welcome! We’re glad you’ve chosen Second Bloom Health to support your menopause care journey. First, download our app from the Apple or Google App Store, sign into your member portal and message your coach. Initially, spend some time thinking about the symptoms or changes you’d like to discuss with your coach to understand how the Second Bloom Health program can assist you in achieving your desired outcomes. Once you are connected with your coach, you will be able to message, text, email, call, or video conference with your coach to discuss where you are in your journey and what you would like to achieve.  Our coaches will suggest elements of the Second Bloom Health Program for you to review or perform and then work with you to achieve your goals."
    },
    {
        id:9,
        question:"What should I expect from my Coach?",
        points: ["Learn about the Second Bloom Health Content, community and SBTV",
        "Discover easy ways to incorporate movement, relaxation, nutrition and sleep",
        "Talk through your goals for menopause",
        "Review your Menopause Experience Survey",
        "Go over any pertinent medical and social history", 
        "Assess your menopause symptoms and general health",
        "Discuss general menopause and preventative care best practices",
        "Discuss how to work with your coach to get the most out of your Second Bloom Health experience. After your initial meeting, your coach will constantly monitor your progress by reviewing and responding to the input you provide on the app. This data-driven approach will help you meet your goals."],
        answer:"You’ll first meet with your menopause certified coach. Together, you’ll discuss the topics that matter to you and start understanding how you can effectively use the Second Bloom Health program to guide your menopause journey. You can expect to: "
    },
    {
        id:10,
        question:"Who are my Second Bloom Health Coaches and how are they trained?",
        points:[],
        answer:"Your Second Bloom Health coaches are certified experts who have been trained according to Dr. Mache Seibel’s holistic methods and NAMS (North American Menopause Society) guidelines. With our holistic, whole-person focus, we offer personalized support for your menopause experience, wherever you are in your journey."
    }

]