import React from "react";
import "./introducing.css";

import one from "../../assets/1.png";
import two from "../../assets/newimages/new2.png";
import three from "../../assets/3.png";
import four from "../../assets/newimages/new4.png";

import { FaCommentAlt } from "react-icons/fa";
import { GiHeartBeats } from "react-icons/gi";
import { FaMobile } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";

const Introducing = () => {
  return (
    <div className="sb__introducing">
      <div className="sb__introducing-title section__padding">
        <h1>
        Everything You Need at Your Fingertips
        </h1>
      </div>
      <div className="sb__introducing-circleimgtext">
        <CircleImgtext
          img={one}
          icon={<FaCommentAlt style={{ color: "var(--violet)" }} />}
          icontext="Chat with your personal Coach"
          para="Text or call your coach on your terms for questions, accountability, personalized feedback and support"
        />

        <CircleImgtext
          img={two}
          icon={<GiHeartBeats style={{ color: "var(--blue)" }} />}
          icontext="Holistic Menopause Content"
          para="Get lifestyle support and the latest menopause information along with expert-led sessions."
        />

        <CircleImgtext
          img={three}
          icon={<FaMobile style={{ color: "var(--pink)" }} />}
          icontext="Easy-To-Use Mobile App"
          para="Navigate the areas in the App that matter to you. Can’t find 
          it? Your coach is there to help!"
        />

        <CircleImgtext
          img={four}
          icon={<BsFillPeopleFill style={{ color: "var(--blue)" }} />}
          icontext="HIPAA Compliant Platform"
          para="Your security is our priority. All data is protected to provide member privacy and confidentiality."
        />
      </div>
    </div>
  );
};

const CircleImgtext = (props) => {
  return (
    <div className="circleimgtext-box">
      <div className="circleimg">
        <img src={props.img} alt="" />
      </div>
      <div className="circleimg-icontext">
        <div>{props.icon}</div>
        <div>
          <p>{props.icontext}</p>
        </div>
      </div>
      <div className="circleimg-para">
        <h5>{props.para}</h5>
      </div>
    </div>
  );
};

export default Introducing;
