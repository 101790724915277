import React from "react";
import "./subscribe.css";

const Subscribe = () => {
  return (
    <div className="sb__subscribe-div">
      <div className="sb__subscribe-mainbox">
        <div className="sb__subscribe-title">
          <h3>Join our email to stay up to date on our latest developments.</h3>
          {/* <p>Join our email to stay up to date on our launch.</p> */}
        </div>
        <div className="sb__subscribe-input">
          <input type="email" placeholder="Your Email Address" />
          <button className="subscribe-button" type="button">SUBSCRIBE</button>
        </div>
        <div className="sb__subscribe-title">
          <p>For media inquiries, please contact: media@secondbloomhealth.com</p>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
