import React from "react";
import "./philosophy.css";
import philoimg from '../../assets/newimages/philoimg.jpg';



const Philosophy = () => {
  return (
    <div className="sb__coach-div">
        <div className="sb__coach-main section__padding">
      <div className="trust-title philosophy-title">
        <h1>Our Philosophy</h1>
      </div>
      <div className="sb__coach-subtitle">
        <div className="trust-para philosophy-para">
          <p>
            Every day, 6,000 US women join 50-million of their sisters by
            entering menopause. Although transitioning into menopause is as
            normal as transitioning into puberty, menopause is often viewed as a
            taboo subject. As a result, over 70% of women get no treatment for
            their menopause symptoms.<br></br><br></br>Women represent roughly half the workforce,
            and approximately 45% of those women are in perimenopause or
            menopause. These women are critical to the workforce and need
            support during the prime of their careers.<br></br><br></br>Second Bloom Health was
            developed to proactively put menopause policies and programs into
            the workplace. We support the menopause experience and improve workability, retention, employee happiness, and business’ bottom line
            while reducing legal risk. Our goal is to provide a holistic
            platform with evidence-based education and coaching support to
            destigmatize menopause and to improve the lives of women both at
            home and at work.
          </p>
        </div>
        <div className="trust-img newphilosophy-img">
          <img src={philoimg} alt=""></img>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Philosophy;
