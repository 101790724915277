import React from "react";
import { Link } from "react-router-dom";
import "./landingpage.css";
// import landingimg from "../../assets/landimg2.png";
import page1img from '../../assets/newimages/page1img.png';
import Threesteps from "../newpage2/threesteps";

const Landingpage = () => {
  return (
    <div className="sb__landing section__padding" id="home">
      <div className="sb__landing-title">
        <h1>Holistic online perimenopause and menopause education and support</h1>
      </div>
      <div className="sb__landing-subtitle">
        <div>
          <p>Start feeling better and more in control...NOW!</p>
          <p className="landingpage_bullets">
            <ul>
              <li><span className="landing-bold">Evidence-based</span> content</li>
              <li><span className="landing-bold">Personalized 1:1</span> coaching support</li>
              <li><span className="landing-bold">Expert-led</span> educational sessions</li>
              <li><span className="landing-bold">Private online</span> community</li>
            </ul>
          </p>

          <div className="sb__landing-button">
            <Link to="/individual"><button type="button">Join us</button></Link>
          </div>

          {/* <h3>Our program is evidence based on<br></br>Dr. Mache Seibel's treatment of over 10,000 women on their menopause journey</h3> */}
        </div>
        <div className="sb__landing-image">
          <img src={page1img} alt="" />
        </div>
      </div>

      <Threesteps/>

      {/* <div className="sb__mission">
        <div className="sb__mission-title">
          <h1>Our Mission</h1>
        </div>
        <div className="sb__mission-subtitle">
          <p>
            We foster better <span className="holistic">“holistic”</span> health
            through<br></br>a <span className="personalized">personalized </span> 
            understanding of a woman’s<br></br>individualized journey through
            <span className="menopause"> menopause</span>
          </p>
        </div>
        <div className="sb__mission-para">
          <p>
            We help midlife women get the information and support
            they need for a healthier, happier life, and a more positive,
            productive work experience.
          </p>
        </div>
        <div className="sb__mission-images">
          <div className="mission-img-div">
            <div className="mission-img_one">
              <img src={img1} alt=""></img>
            </div>
            <div className="mission-text_one">
              <p>Life strategies for menopausal relief</p>
            </div>
          </div>
          <div className="mission-img-div">
            <div className="mission-img_three">
              <img src={img3} alt=""></img>
            </div>
            <div className="mission-text_three">
              <p>24/7 individual digital support</p>
            </div>
          </div>
          <div className="mission-img-div">
            <div className="mission-img_two">
              <img src={img2} alt=""></img>
            </div>
            <div className="mission-text_two">
              <p>Personalized journey</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Landingpage;
