import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
// import logo from "../../assets/new_sb_logo.png";
import logonew from '../../assets/newlogo/newlogo3.png';
import "./navbar.css";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar-bg">
      <div className="sb__navbar">
        <div className="sb__navbar-links">
          <div className="sb__navbar-links_logo">
            <NavLink to="/">
              <img src={logonew} alt="logo" />
            </NavLink>
          </div>
          <div className="sb__navbar-links_container">
            <p>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#E75BA4" : "",
                })}
                to="/whatwedo"
              >
                ABOUT
              </NavLink>
            </p>
            <p>
              <NavLink style={({ isActive }) => ({
                  color: isActive ? "#E75BA4" : "",
                })} to="/employer">
                ORGANIZATIONS
              </NavLink>
            </p>
            {/* <p>
              <NavLink style={({ isActive }) => ({
                  color: isActive ? "#E75BA4" : "",
                })} to="/healthplan">
                HEALTH PLANS
              </NavLink>
            </p> */}
            <p>
              <NavLink style={({ isActive }) => ({
                  color: isActive ? "#E75BA4" : "",
                })} to="/individual">
                INDIVIDUALS
              </NavLink>
            </p>
            <p>
              <NavLink style={({ isActive }) => ({
                  color: isActive ? "#E75BA4" : "",
                })} to="/faq">
                FAQ
              </NavLink>
            </p>
            
          </div>
        </div>
        <div className="sb__navbar-button">
          <a href="#contact">
            <Link  to="/individual"><button type="button">JOIN US</button></Link>
          </a>
        </div>
        <div className="sb__navbar-menu">
          {toggleMenu ? (
            <RiCloseLine
              color="#000"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#000"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className="sb__navbar-menu_container scale-up-center">
              <div className="sb__navbar-menu_container-links">
                <p>
                  <Link to="/whatwedo">ABOUT</Link>
                </p>
                <p>
                  <Link to="/employer">ORGANIZATIONS</Link>
                </p>
                {/* <p>
                  <Link to="/healthplan">HEALTH PLANS</Link>
                </p> */}
                <p>
                  <Link to="/individual">INDIVIDUALS</Link>
                </p>
                <p>
                  <Link to="/faq">FAQ</Link>
                </p>
              </div>
              <div className="sb__navbar-menu_container-links-sign">
                <a href="#contact">
                  <button type="button">JOIN US</button>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="sb__navbar-below">
        <p>
          Does your employer offer the SecondBloom Health Benefit?{" "}
          <a href="#contact">Activate</a>
        </p>
      </div> */}
    </div>
  );
};

export default Navbar;
