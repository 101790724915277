import React from "react";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home";
import { Route, Routes } from "react-router-dom";
import Whatwedo from "./components/whatwedo/whatwedo";
import Employer from "./components/employer/employer";
import Faq from "./components/faq/faq";
import Individual from "./components/individual/individual";
import Healthplan from "./components/healthplan/healthplan";
import About from "./components/about/about";
import Embr from "./components/embr/embr";
import Resource from "./components/resourcepage/resource";
import Terms from "./components/terms/terms";
import Privacy from "./components/privacy/privacy";

function App() {
  return (
    <div className="App">
      
      <Navbar />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/whatwedo" element={<Whatwedo/>}/>
        <Route path="/employer" element={<Employer/>}/>
        <Route path="/faq" element={<Faq/>}/>
        <Route path="/individual"  element={<Individual/>}/>
        <Route path="/healthplan" element={<Healthplan/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/embr" element={<Embr/>}/>
        <Route path="/resource" element={<Resource/>}/>
        <Route path="/terms" element={<Terms/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
      </Routes>
    </div>
  );
}

export default App;
