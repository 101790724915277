import React from "react";
import "./threesteps.css";
import img1 from '../../assets/newimages/page2_img1.jpg';
import img2 from '../../assets/newimages/page2_img2.jpg';
import img3 from '../../assets/newimages/page2_img3.jpg';


const Threesteps = () => {
  return (
    <div className="threesteps section__padding">
      <div className="threesteps-title">
        <h1>Second Bloom Health is Easy as 1, 2, 3</h1>
      </div>
      <div className="threesteps-imagesdesc">
          <div className="threesteps-img-des-div">
            <div className="threesteps-img_one">
              <img className="pagetwo_images" src={img1} alt=""></img>
            </div>
            <div className="threesteps-text_one">
              <p>STEP 1<br></br>Take a menopause assessment to identify your most pressing symptoms and questions</p>
            </div>
          </div>
          <div className="threesteps-img-des-div">
            <div className="threesteps-img_two">
              <img className="pagetwo_images" src={img2} alt=""></img>
            </div>
            <div className="threesteps-text_two">
              <p>STEP 2<br></br>Watch the welcome video, get a quick overview of the program and tips on how to maximize your experience</p>
            </div>
          </div>
          <div className="threesteps-img-des-div">
            <div className="threesteps-img_three">
              <img className="pagetwo_images" src={img3} alt=""></img>
            </div>
            <div className="threesteps-text_three">
              <p>STEP 3<br></br>Meet your coach to establish your goals and start getting the information and support you need</p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Threesteps;
