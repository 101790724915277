import React from "react";
import "./testimonial.css";

const Carousel = (props) => {
  return (
    <div className="carousel-div">
      <div className="carousel-compliment">
        <h3>
          {props.content}
        </h3>
      </div>
      <div className="carousel-name">
        <p>-{props.client}</p>
      </div>
    </div>
  );
};

export default Carousel;
