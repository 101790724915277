import React from "react";
import "./embr.css";
import Footer from '../footer/Footer';
import embrwatch from "../../assets/embr_watch.png";
import embrphone_new from "../../assets/embrphone_new.png";
import sleep from "../../assets/sleep.png";
import hotflash from "../../assets/hotflash.png";
import life from "../../assets/improvelife.png";
import reducestress from "../../assets/reducestress.png";
import watchcharger from "../../assets/watchcharger.png";

import { FaHeart } from "react-icons/fa";
import { FaMobile } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaHandHoldingHeart } from "react-icons/fa";

const Embr = () => {
  return (
    <div>
      <div className="sb__embr">
        <div className="section__padding">
          <div className="embr-landing">
            <div className="embr-landing-text">
              <h2>
                Hot Flashes have met their match with
                <span className="embr"> EMBR Wave</span><br></br>
                <span className="award">
                  Award-winning device for immediate<br></br>menopause symptom relief
                </span>
              </h2>
              <p>
                Our Combined Personalized, Precision Menopause Solution Provides
                Individual Benefits at Enterprise Scale
              </p>
              {/* <button>Learn more</button> */}
            </div>
            <div className="embr-landing-img">
              <img src={embrwatch} alt="" />
            </div>
          </div>
    </div>

          <div className="below-wave">
        <div className="below-wave-title">
          <p>
            Introducing a Combined Offering of Second Bloom Health and the EMBR
            Wave providing a Personalized, Precision Menopause Solution for
            Employers, Health Plans and Individuals
          </p>
        </div>

        <div className="embr-phone">
          <div className="embr-phone-img">
            <img src={watchcharger} alt="" />
          </div>

          <div className="embr-phone-icontext-group">
            <div className="embr-phone-icons">
              <div className="embr-phone-icon-one">
                <FaCommentAlt style={{ color: "var(--violet)" }} />
              </div>
              <div className="embr-phone-icon-one-text">
                <p>Certified Menopause Coaches</p>
              </div>
            </div>

            <div className="embr-phone-icons">
              <div className="embr-phone-icon-one">
                <FaHandHoldingHeart style={{ color: "var(--violet)" }} />
              </div>
              <div className="embr-phone-icon-one-text">
                <p>Holistic Menopause Content</p>
              </div>
            </div>

            <div className="embr-phone-icons">
              <div className="embr-phone-icon-one">
                <FaMobile style={{ color: "var(--violet)" }} />
              </div>
              <div className="embr-phone-icon-one-text">
                <p>Easy-To-Use App</p>
              </div>
            </div>

            <div className="embr-phone-icons">
              <div className="embr-phone-icon-one">
                <BsFillPeopleFill style={{ color: "var(--violet)" }} />
              </div>
              <div className="embr-phone-icon-one-text">
                <p>HIPAA CompliantPlatform</p>
              </div>
            </div>

            <div className="embr-phone-icons">
              <div className="embr-phone-icon-one">
                <FaMobile style={{ color: "var(--violet)" }} />
              </div>
              <div className="embr-phone-icon-one-text">
                <p>An EMBR Wave Device</p>
              </div>
            </div>
          </div>
        </div>
      </div>

   <div className="section__padding">
          <div className="embr-phone">
            <div className="embr-phone-img">
              <img src={embrphone_new} alt="" />
            </div>

            <div className="embr-phone-icontext-group">
              <div className="embr-phone-icons">
                <div className="embr-phone-icon-one">
                  <FaHeart style={{ color: "var(--blue)" }} />
                </div>
                <div className="embr-phone-icon-one-text">
                  <p>
                    Immediate relief from hot flashes at the touch of a button
                  </p>
                </div>
              </div>

              <div className="embr-phone-icons">
                <div className="embr-phone-icon-one">
                  <FaMobile style={{ color: "var(--blue)" }} />
                </div>
                <div className="embr-phone-icon-one-text">
                  <p>
                    Added control via App to personalize the feel and duration
                  </p>
                </div>
              </div>

              <div className="embr-phone-icons">
                <div className="embr-phone-icon-one">
                  <FaBriefcase style={{ color: "var(--blue)" }} />
                </div>
                <div className="embr-phone-icon-one-text">
                  <p>Stylish design fits into a woman’s lifestyle</p>
                </div>
              </div>

              <div className="embr-phone-icons">
                <div className="embr-phone-icon-one">
                  <FaCommentAlt style={{ color: "var(--blue)" }} />
                </div>
                <div className="embr-phone-icon-one-text">
                  <p>
                    Individuals using EMBR Wave on
                    average 5+ hours/day
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>

         
        
      </div>

    


      <div className="sb__embr">
   <div className="section__padding">
      <div className="embr_wave">
            <div className="embr_wave-title">
              <h1>EMBR Wave: Real Relief, Real Value</h1>
              {/* <p>
                Enhances the lived experience of individuals managing the
                symptoms of menopause
              </p> */}
            </div>

            <div className="embr_wave-para">
              <p>
                Individuals describe the EMBR Wave as a life changer that has a
                positive impact on the quality of their lives and allows women
                to<br></br>“take back control”
              </p>
            </div>

            <div className="embr_wave-icontext section__padding">
              <div className="hotflash">
                <img src={hotflash} alt="" />
                <p>HOT FLASH RELIEF</p>
              </div>
              <div className="bettersleep">
                <img src={sleep} alt="" />
                <p>BETTER SLEEP</p>
              </div>
              <div className="life">
                <img src={life} alt="" />
                <p>IMPROVED LIFE QUALITY</p>
              </div>
              <div className="reducestress">
                <img src={reducestress} alt="" />
                <p>REDUCED STRESS</p>
              </div>
            </div>

            <a href="https://embrlabs.com/" target='blank'><div className="embr_wave-button">
              <button>Get an EMBR Wave today</button>
            </div></a>
          </div>
          </div>
          </div>

      {/* <div className="embr-last">
        <div className="embr-last-text section__padding">
          <h2>
            SECOND BLOOM HEALTH:<br></br>Platform for proven menopause program
            content, coaching, and community engagement.
          </h2>
          <p>
            Precise menopause guidance tailored to each individual and aligned
            to clinical best practices.<br></br><br></br>Proprietary menopause coaching
            curriculum with licensed coaches matched to individuals based on
            acuity and co-morbidities.<br></br><br></br>Extensible, integrated platform of
            education, guidance, activity tracking, and support
          </p>
        </div>
        <div className="embr_wave-button">
              <button>Get your EMBR Wave today</button>
            </div>
      </div> */}
      <Footer/>
    </div>
  );
};

export default Embr;
